.op-bloque {
  margin-bottom: 5px;
  background-color: #ffffff;
  box-shadow: 3px 3px 5px #888888;
  flex-basis: 100%;
}

.op-bloque .op-bloque-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3498db;
  height: 40px;
  padding: 0 10px;
}

.op-bloque .op-bloque-head .op-bloque-actions {
  display: flex;
}

.op-bloque .op-bloque-head .op-bloque-actions button {
  margin-right: 5px;
}

.op-bloque .op-bloque-head label {
  font-size: 20px;
  color: #3498db;
  cursor: pointer;
  animation: bloque-label-loader 500ms ease-in;
  transform: rotate(90deg);
  transition: transform 0.5s;
}

.op-bloque .op-bloque-head label span {
  height: 4px;
  width: 12px;
  background-color: #3498db;
  display: block;
  margin: 5px 0;
  transition: 0.5s ease-in-out;
}

.op-bloque .op-bloque-head label span:nth-of-type(1) {
  position: relative;
  top: 3px;
  transform: rotate(45deg);
}

.op-bloque .op-bloque-head label span:nth-of-type(2) {
  transform: rotate(-45deg);
}

.op-bloque .op-bloque-content {
  position: relative;
}

.op-bloque.op-close {
  height: 40px;
}

.op-bloque.op-close .op-bloque-head label {
  transform: rotate(0deg);
}

.op-bloque.op-close .op-bloque-content {
  display: none;
}

@keyframes bloque-label-loader {
  0% {
    transform: rotate(0) scale(1.5);
  }
  100% {
    transform: rotate(90deg) scale(1);
  }
}
