.op-modal.op-calendar {
  flex-basis: 504px;
  color: #ffffff;
}
.op-content-calendar {
  display: flex;
  position: relative;
}

.op-content-calendar .content-months,
.op-content-calendar .content-main,
.op-content-calendar .content-events {
  display: flex;
  flex-direction: column;
}

.op-content-calendar .content-months {
  background-color: #222831;
}

.op-content-calendar .content-months .months {
  display: flex;
  flex-direction: column;
  padding: 0 1px;
}

.op-content-calendar .content-months .months > div {
  padding: 3px 30px;
  cursor: pointer;
}

.op-content-calendar .content-months .months > div.selected {
  background-color: #00adb5a1;
}

.op-content-calendar .content-main {
  max-width: 362px;
  background-color: #393e46;
  position: relative;
}

.op-content-calendar .content-main .calendar-icon {
  position: absolute;
  display: none;
}

.op-content-calendar .content-month,
.op-content-calendar .content-year {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  font-size: 30px;
}

.op-content-calendar .content-month {
  color: #00adb5;
  font-weight: 600;
  display: flex;
  position: relative;
}

.op-content-calendar .content-month div {
  width: 350px;
  text-align: center;
  position: absolute;
  animation-duration: 0.5s;
}

.op-content-calendar .content-month div.off {
  opacity: 0;
  animation-timing-function: ease-out;
  animation-name: month-loader-off;
}

.op-content-calendar .content-month div.on {
  opacity: 1;
  animation-timing-function: ease-in;
  animation-name: month-loader-on;
}

.op-content-calendar .content-week,
.op-content-calendar .content-days {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.op-content-calendar .content-week > div,
.op-content-calendar .content-days .day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.op-content-calendar .content-days .day label:not(.enabled) {
  color: #6c6a6adb;
}

.op-content-calendar .content-days .day label.enabled {
  font-weight: 400;
  cursor: pointer;
}

.op-content-calendar .content-days .day input:checked ~ label {
  border: 2px solid #ffffff;
  padding: 6px;
  color: #00adb5;
}

.op-content-calendar
  .content-days
  .day
  input:not(:checked)
  ~ label.enabled:hover {
  background-color: rgba(255, 255, 255, 0.12);
  padding: 9px;
}

.op-content-calendar .content-days .day input {
  display: none;
}

.op-content-calendar .content-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;
  border-top: 1px solid #027e84;
}

.op-content-calendar .content-footer button {
  background-color: #00adb5a1;
  color: #ffffff;
}

@keyframes month-loader-on {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes month-loader-off {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}

@media only screen and (max-width: 576px) {
  .op-modal.op-calendar {
    flex-basis: 350px;
  }

  .op-content-calendar .content-months {
    position: absolute;
    left: -100%;
    z-index: 2;
    opacity: 0;
    transition: all ease-in-out 0.5s;
  }

  .op-content-calendar.on .content-months {
    left: 0;
    opacity: 1;
  }

  .op-content-calendar .content-main .calendar-icon {
    display: block;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
}
