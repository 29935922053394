.op-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 20px 0;
  background-color: #ffffff;
  gap: 20px;
}

.op-form-group {
  position: relative;
  margin-bottom: 10px;
  flex: 0 1 100%;
}

.op-form-group.op-col-2 {
  flex: 0 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.op-form-group.op-col-3 {
  flex: 0 1 calc(33.33% - 14px);
  max-width: calc(33.33% - 14px);
}

.op-form-group.op-col-4 {
  flex: 0 1 calc(25% - 15px);
  max-width: calc(25% - 15px);
}

.op-form-group.op-col-5 {
  flex: 0 1 calc(20% - 15px);
  max-width: calc(20% - 15px);
}

.op-form-group.op-col-6 {
  flex: 0 1 calc(16.66% - 17px);
  max-width: calc(16.66% - 17px);
}

.op-form-group input,
.op-form-group select {
  box-shadow: none;
  border: 1px solid #cccccc;
  padding: 15px 10px 15px 10px;
  font-size: 14px;
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
  z-index: 0;
}

.op-form-group select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 13px;
  height: 50px;
}

.op-form-group .op-loading {
  background-color: #ffffff;
  -webkit-mask: url('../../assets/loader.svg') no-repeat center;
  mask: url('../../assets/loader.svg') no-repeat center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  cursor: not-allowed;
}

.op-form-group input:focus,
.op-form-group select:focus {
  outline: none;
}

.op-form-group > label {
  position: absolute;
  font-weight: normal;
  left: 10px;
  top: 15px;
  color: #999999;
  pointer-events: none;
  font-size: 14px;
  z-index: 1;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.op-form-group select ~ label {
  top: -10px;
  background-color: #ffffff;
  padding: 0 2px;
  left: 8px;
}

.op-form-group.searcheable,
.op-form-group.op-calendar {
  display: flex;
  align-items: center;
}

.op-form-group.searcheable > input {
  flex-grow: 1;
}

.op-form-group.searcheable input:focus,
.op-form-group.searcheable input:valid {
  border-right: none;
}

.op-form-group.icon .icon {
  position: absolute;
  right: 5px;
  top: 12px;
  cursor: pointer;
}

.op-form-group input:focus ~ label,
.op-form-group input:valid ~ label,
.op-form-group input:disabled ~ label,
.op-form-group select:focus ~ label,
.op-form-group select:valid ~ label {
  top: -10px;
  left: 8px;
  color: #3498db;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffffff;
  padding: 0 2px;
}

.op-form-group input[type='date'] ~ label {
  top: -10px;
  left: 8px;
  background-color: #ffffff;
  padding: 0 2px;
}

.op-form-group input:disabled,
.op-form-group select:disabled {
  background-color: #cccccc75;
  cursor: not-allowed;
  color: #000000;
}

.op-form-group input:disabled ~ label,
.op-form-group select:disabled ~ label {
  background: linear-gradient(#ffffff, #e8e1e194);
  color: #999999;
}

.op-form-group .bar.bottom {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
}

.op-form-group .bar.left {
  position: absolute;
  display: block;
  height: 100%;
  width: 2px;
  top: 0;
}

.op-form-group .bar.right {
  position: absolute;
  display: block;
  height: 100%;
  width: 2px;
  top: 0;
  right: 0;
}

.op-form-group .bar.top {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
}

.op-form-group .bar::before {
  content: '';
  position: absolute;
  background: #3498db;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.op-form-group .bar.bottom::before {
  height: 2px;
  width: 0;
  bottom: 0;
  left: 0;
}

.op-form-group .bar.left::before {
  height: 0;
  width: 2px;
  bottom: 0;
  left: 0;
}

.op-form-group .bar.right::before {
  height: 0;
  width: 2px;
  top: 0;
  right: 0;
}

.op-form-group .bar.top::before {
  height: 2px;
  width: 0;
  top: 0;
  right: 0;
}

.op-form-group input:focus ~ .bar.bottom::before,
.op-form-group input:valid ~ .bar.bottom::before,
.op-form-group input:focus ~ .bar.top::before,
.op-form-group input:valid ~ .bar.top::before,
.op-form-group select:focus ~ .bar.bottom::before,
.op-form-group select:valid ~ .bar.bottom::before,
.op-form-group select:focus ~ .bar.top::before,
.op-form-group select:valid ~ .bar.top::before {
  width: 100%;
}

.op-form-group input:focus ~ .bar.left::before,
.op-form-group input:valid ~ .bar.left::before,
.op-form-group input:focus ~ .bar.right::before,
.op-form-group input:valid ~ .bar.right::before,
.op-form-group select:focus ~ .bar.left::before,
.op-form-group select:valid ~ .bar.left::before,
.op-form-group select:focus ~ .bar.right::before,
.op-form-group select:valid ~ .bar.right::before {
  height: inherit;
}

.op-form-group.op-checkbox-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.op-form-group.error .bar::before {
  background: red;
}

.op-form-group.error .bar.left::before,
.op-form-group.error .bar.right::before {
  height: inherit;
}

.op-form-group.error .bar.top::before,
.op-form-group.error .bar.bottom::before {
  width: 100%;
}

.op-form-group.error .op-form-error,
.op-form-group.error input:focus ~ label,
.op-form-group.error input:valid ~ label,
.op-form-group.error select:focus ~ label,
.op-form-group.error select ~ label {
  color: red;
}

.op-form-group.error .op-form-error {
  font-size: 12px;
  position: absolute;
}

.op-form-group.op-calendar.error .op-form-error {
  bottom: -18px;
}

.op-form-group.op-col-1.totales {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.op-form-group.op-col-1.totales .fila {
  display: flex;
}

.op-form-group .op-info {
  position: absolute;
  top: -25px;
  right: 2px;
}

.op-form-group .op-info span {
  opacity: 0;
  background-color: #393e46;
  color: #ffffff;
  padding: 3px;
  border-radius: 5px;
  font-size: 11px;
  position: absolute;
  top: 2px;
  right: 25px;
  width: 190px;
  z-index: -1;
}

.op-form-group .op-info svg:hover ~ span {
  opacity: 1;
  z-index: 3;
}

.op-form-group .op-info span.on {
  opacity: 1;
  z-index: 3;
}

.op-form-group .op-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.op-form-group .op-file > div {
  cursor: pointer;
}

@media only screen and (max-width: 1055px) {
  .op-form-group.op-col-4 {
    flex: 1 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media only screen and (max-width: 885px) {
  .op-form-group.op-col-3 {
    flex: 1 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 768px) {
  .op-form-group.op-col-4 {
    flex: 1 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 576px) {
  .op-form-group.op-col-2 {
    flex: 0 1 100%;
    max-width: 100%;
  }

  .op-form-group.op-col-3 {
    flex: 0 1 100%;
    max-width: 100%;
  }

  .op-form-group.op-col-4 {
    flex: 0 1 100%;
    max-width: 100%;
  }
}
